import React, { type ComponentType } from 'react';
import type { ExecutionResult, MutationFunctionOptions } from 'react-apollo';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_EXPERIENCE,
} from '@confluence/experience-tracker';
import { scrollCommentIntoView, scrollToElementInEditor } from '@confluence/comments-util';
import {
	handleMutationFailure,
	handleCreateReplySuccess as handleCreateInlineReplySuccess,
	updateApolloCacheCallback as updateInlineApolloCacheCallback,
	getCommentCreationLocation,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import { fg } from '@confluence/feature-gating';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type {
	CommentLocation,
	CreateInlineReplyMutationType,
	CreateInlineReplyMutationVariables,
} from '@confluence/inline-comments-queries';
import type { GraphQLContentStatus } from '@confluence/comments-panel-queries';
import {
	updateApolloCacheReplyCallback as updateGeneralApolloCacheCallback,
	handleCreateReplySuccess as handleCreateGeneralReplySuccess,
} from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { CommentType } from '@confluence/comments-data';
import type { CommentData, ReplyData } from '@confluence/comments-data';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import {
	Platform,
	ContentRepresentation,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralReplyMutation';
import type {
	CreateGeneralReplyMutationData,
	CreateGeneralReplyMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralReplyMutation';
import { ViewValues } from '@confluence/comments-panel-utils';

import type { CommentThreadProps } from '../components/CommentThread';

export const DEFAULT_NUM_VISIBLE_REPLIES = 2;

export enum ViewReplyOptions {
	ALL = 'ALL', // shows all replies
	DEFAULT = 'DEFAULT', // shows up to 3 unread comments
	HIDDEN = 'HIDDEN',
	LIMITED = 'LIMITED',
}

type HandleCommentThreadSelectionProps = {
	parentComment: CommentData;
	pageMode: PageMode;
	setCurrentlySelectedCommentMarkerRef: (parentCommentMarkerRef: string | undefined) => void;
	eventEmitter?: AnnotationUpdateEmitter;
};

type HandlePanelSelectionAndScrollProps = {
	threadKey: string | null;
	isResolved: boolean;
	pageMode: PageMode;
	setCurrentlySelectedCommentMarkerRef: (parentCommentMarkerRef: string | undefined) => void;
	eventEmitter?: AnnotationUpdateEmitter;
	commentType: CommentType;
	scrollCommentsPanelToThread?: (annotationId: string) => void;
	isKeyboardShortcutTriggered?: boolean;
};

export const handleCommentThreadSelection = ({
	parentComment,
	pageMode,
	setCurrentlySelectedCommentMarkerRef,
	eventEmitter,
}: HandleCommentThreadSelectionProps) => {
	const threadKey =
		parentComment.type === CommentType.INLINE
			? (parentComment.location as CommentLocation).inlineMarkerRef
			: parentComment.id;
	const isResolved = !parentComment.isOpen;

	handlePanelSelectionAndScroll({
		threadKey,
		isResolved,
		pageMode,
		setCurrentlySelectedCommentMarkerRef,
		eventEmitter,
		commentType: parentComment.type,
	});
};

export const handlePanelSelectionAndScroll = ({
	threadKey,
	isResolved,
	pageMode,
	setCurrentlySelectedCommentMarkerRef,
	eventEmitter,
	commentType,
	scrollCommentsPanelToThread = undefined,
	isKeyboardShortcutTriggered = false,
}: HandlePanelSelectionAndScrollProps) => {
	// We only scroll the document for non-resolved inline comments or page comments
	if (threadKey && !isResolved) {
		setCurrentlySelectedCommentMarkerRef(threadKey);
		if (commentType === CommentType.INLINE) {
			const commentElement = document.getElementById(threadKey);
			if (commentElement) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
					eventEmitter?.emit('setselectedannotation', threadKey);

					// Handle case where a comment is never clicked but is still selected via KB shortcuts
					if (
						isKeyboardShortcutTriggered &&
						scrollCommentsPanelToThread &&
						fg('comments_panel_updated_scrolling_behavior')
					) {
						scrollCommentsPanelToThread(threadKey);
					}
				} else {
					eventEmitter?.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
						annotationId: threadKey,
					});
					scrollCommentIntoView({
						commentElement,
						isFabricPage: true,
						useInstantScroll: false,
						commentThreadAnnotationId: threadKey,
						isCommentsPanel: true,
						scrollCommentsPanelToThread,
						isKeyboardShortcutTriggered,
					});
				}
			}
		}

		// Page comment case
		if (
			commentType === CommentType.GENERAL &&
			fg('confluence-frontend-comments-panel-design-update')
		) {
			// TODO: When we update the footer comment containers, this needs to be updated
			const commentContainerId = `comment-${threadKey}`;
			const commentElement = document.getElementById(commentContainerId);
			if (commentElement) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					eventEmitter?.emit('setselectedannotation', null);
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
				} else {
					eventEmitter?.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
					scrollCommentIntoView({
						commentElement,
						isFabricPage: true,
						useInstantScroll: false,
						commentThreadAnnotationId: threadKey,
						isCommentsPanel: true,
						isAbove: true,
					});
				}
			}
		}
	}
};

type SaveProps = {
	pageId: string;
	parentCommentId: string;
	cloudId: string;
	adf: object;
	onSuccess: () => void;
	pageMode: PageMode;
	editCommentQueryId?: string;
	setCommentForEdit: React.Dispatch<React.SetStateAction<string>>;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	pageType: string;
	experienceTracker: ExperienceTrackerAPI;
	createInlineReplyFn: (
		options?:
			| MutationFunctionOptions<CreateInlineReplyMutationType, CreateInlineReplyMutationVariables>
			| undefined,
	) => Promise<ExecutionResult<CreateInlineReplyMutationType>>;
	createGeneralReplyFn: (
		options?:
			| MutationFunctionOptions<CreateGeneralReplyMutationData, CreateGeneralReplyMutationVariables>
			| undefined,
	) => Promise<ExecutionResult<CreateGeneralReplyMutationData>>;
	selectedAnnotation?: AnnotationInfo;
	addReplyToCommentThread: (ref: string, reply: ReplyData, commentType: CommentType) => void;
	threadKey: string;
	updateReadCommentsListState: (
		transform: (prevReadCommentsListState: Set<string>) => Set<string>,
	) => void;
	commentType: CommentType;
	searchSessionId?: string | null;
	isReactionsEnabled?: boolean;
};

export const onSaveReply = async ({
	pageId,
	parentCommentId,
	cloudId,
	adf,
	onSuccess,
	pageMode,
	editCommentQueryId,
	setCommentForEdit,
	createAnalyticsEvent,
	pageType,
	experienceTracker,
	createInlineReplyFn,
	createGeneralReplyFn,
	selectedAnnotation,
	addReplyToCommentThread,
	threadKey,
	updateReadCommentsListState,
	commentType,
	searchSessionId,
	isReactionsEnabled,
}: SaveProps) => {
	const isInlineComment = commentType === CommentType.INLINE;
	const isEditor = pageMode === PageMode.EDIT;

	const createReplyFn = isInlineComment
		? createInlineReplyFn({
				variables: {
					input: {
						containerId: pageId,
						parentCommentId,
						commentBody: {
							value: JSON.stringify(adf),
							representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
						},
						createdFrom: getCommentCreationLocation(isEditor, pageMode === PageMode.LIVE),
					},
					pageId,
				},
				update: updateInlineApolloCacheCallback(
					'create',
					{
						pageId,
						contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
						annotationId: threadKey,
					},
					parentCommentId,
				),
			})
		: createGeneralReplyFn({
				variables: {
					pageId,
					cloudId,
					input: {
						commentBody: {
							value: JSON.stringify(adf),
							representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
						},
						commentSource: Platform.WEB,
						containerId: pageId,
						parentCommentId,
					},
				},
				update: updateGeneralApolloCacheCallback(
					'create',
					{
						pageId,
						contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
					},
					parentCommentId,
					isReactionsEnabled,
				),
			});

	return createReplyFn
		.then(({ data }: any) => {
			const replyResult = isInlineComment ? data.replyInlineComment : data.createFooterComment;
			const reply: ReplyData = {
				isUnread: false,
				type: isInlineComment ? CommentType.INLINE : CommentType.GENERAL,
				...replyResult,
			};
			updateReadCommentsListState((prev) => new Set([...prev, reply.id]));
			addReplyToCommentThread(threadKey, reply, commentType);

			if (isInlineComment) {
				handleCreateInlineReplySuccess({
					onSuccess,
					editCommentQueryId,
					setCommentForEdit,
					data,
					createAnalyticsEvent,
					pageId,
					pageType,
					analyticsSource: isEditor ? 'editPageCommentsPanel' : 'viewPageCommentsPanel',
					commentId: parentCommentId,
					pageMode,
					selectedAnnotation,
					getInlineNodeTypes: undefined,
					experienceTracker,
					isEditor,
				});
			} else {
				handleCreateGeneralReplySuccess({
					onSuccess,
					editCommentQueryId,
					setCommentForEdit,
					data,
					createAnalyticsEvent,
					pageId,
					pageType,
					analyticsSource: isEditor ? 'editPageCommentsPanel' : 'viewPageCommentsPanel',
					commentId: parentCommentId,
					pageMode,
					experienceTracker,
					searchSessionId,
				});
			}
		})
		.catch((error: any) => {
			return handleMutationFailure({
				experienceTracker,
				experienceName: isInlineComment
					? REPLY_TO_INLINE_COMMENT_EXPERIENCE
					: ADD_PAGE_COMMENT_EXPERIENCE,
				error,
				commentId: parentCommentId,
				shouldReturnError: true,
			});
		});
};

export type CommentThreadMouseEnterParams = {
	id?: string;
	threadKey?: string;
	setIsThreadHovered: React.Dispatch<React.SetStateAction<boolean>>;
	setHoveredCommentId: React.Dispatch<React.SetStateAction<string | undefined>>;
	pageMode: PageMode;
	eventEmitter?: AnnotationUpdateEmitter;
};

export const handleCommentThreadMouseEnter = ({
	id,
	threadKey,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (threadKey) {
		setIsThreadHovered(true);
		id && setHoveredCommentId(id);
		if (fg('confluence_frontend_comments_panel_v2')) {
			if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
				eventEmitter?.emit('sethoveredannotation', threadKey);
			} else {
				eventEmitter?.emit(AnnotationUpdateEvent.SET_ANNOTATION_HOVERED, {
					annotationId: threadKey,
				});
			}
		}
	}
};

export const handleCommentThreadMouseLeave = ({
	threadKey,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (threadKey) {
		setIsThreadHovered(false);
		setHoveredCommentId(undefined);
		if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
			eventEmitter?.emit('removehoveredannotation', threadKey);
		} else {
			eventEmitter?.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_HOVERED, {
				annotationId: threadKey,
			});
		}
	}
};

export const getCommentThreadsToBatch: (
	comments: CommentData[],
	passThroughProps: any,
	CommentThreadComponent: ComponentType<CommentThreadProps>,
	transitionIndexWithinBatch: number | undefined,
) => React.JSX.Element[] = (
	comments,
	passThroughProps,
	CommentThreadComponent,
	transitionIndexWithinBatch,
) =>
	comments.map((parentComment, index, array) => {
		const isLastComment = index === array.length - 1;

		const shouldRenderCommentTypeSeparator = index === transitionIndexWithinBatch;

		return (
			<CommentThreadComponent
				key={parentComment.id}
				parentComment={parentComment}
				shouldRenderCommentThreadSeperator={!isLastComment}
				shouldRenderCommentTypeSeparator={shouldRenderCommentTypeSeparator}
				{...passThroughProps}
			/>
		);
	});

export const hasUnreadHiddenReply = (reply: ReplyData, hiddenReplies: ReplyData[]) => {
	return hiddenReplies.some(
		(hiddenReply: ReplyData) => hiddenReply.id === reply.id && hiddenReply.isUnread,
	);
};

type UpdateVisibleRepliesProps = {
	currentReplyView: ViewReplyOptions;
	setVisibleReplies: React.Dispatch<React.SetStateAction<ReplyData[]>>;
	allReplies: ReplyData[];
	isParentCommentOpen: boolean;
	latestReplies: ReplyData[];
	parentCommentId: string;
	currentView?: ViewValues;
};

export type LegacyUpdateVisibleRepliesProps = UpdateVisibleRepliesProps & {
	numOfHiddenReplies: React.MutableRefObject<number>;
	commentType: CommentType;
	latestReplyIds: Set<string>;
	shouldShowLatestReplies: boolean;
};

export const legacyUpdateVisibleReplies = ({
	allReplies,
	commentType,
	isParentCommentOpen,
	currentReplyView,
	latestReplies,
	latestReplyIds,
	parentCommentId,
	setVisibleReplies,
	shouldShowLatestReplies,
	numOfHiddenReplies,
}: LegacyUpdateVisibleRepliesProps) => {
	if (currentReplyView === ViewReplyOptions.ALL) {
		if (commentType !== CommentType.GENERAL) {
			setVisibleReplies(allReplies);
		} else {
			const topLevelReplies = allReplies.filter((reply) => reply.parentId === parentCommentId);
			setVisibleReplies(topLevelReplies);
		}
		numOfHiddenReplies.current = 0;
	} else {
		numOfHiddenReplies.current = allReplies.length;
		if (!isParentCommentOpen) {
			// If the parent comment is resolved, we hide all replies on the thread's initial load
			setVisibleReplies([]);
		} else if (
			shouldShowLatestReplies &&
			latestReplies &&
			latestReplyIds &&
			latestReplyIds.size > 0
		) {
			/*
			 * If the parent comment is open, we show the latest replies on the thread's initial load only.
			 * When replies are expaneded and collapsed, all replies are hidden.
			 */
			setVisibleReplies(latestReplies);
			numOfHiddenReplies.current -= latestReplyIds.size;
		}
	}
};

export const updateVisibleReplies = ({
	currentReplyView,
	setVisibleReplies,
	allReplies,
	isParentCommentOpen,
	latestReplies,
	parentCommentId,
	currentView,
}: UpdateVisibleRepliesProps) => {
	if (!isParentCommentOpen && currentView !== ViewValues.RESOLVED) {
		setVisibleReplies([]);
		return;
	}

	switch (currentReplyView) {
		case ViewReplyOptions.ALL:
			const topLevelReplies = getCommentReplies(allReplies, parentCommentId);
			// Make all direct replies to the parent comment visible, excluding any nested replies.
			setVisibleReplies(topLevelReplies);
			break;
		case ViewReplyOptions.HIDDEN:
			setVisibleReplies([]);
			break;
		case ViewReplyOptions.LIMITED:
			setVisibleReplies(latestReplies);
			break;
	}
};

export const getCommentReplies = (replies: ReplyData[], parentCommentId: string) =>
	replies.filter(({ parentId }) => parentId === parentCommentId);

export const getCurrentReplyView = (numberOfReplies: number, isParentCommentOpen: boolean) => {
	if (!fg('confluence_frontend_cp_visible_replies_refactor')) return ViewReplyOptions.DEFAULT;

	if (!isParentCommentOpen) {
		return ViewReplyOptions.HIDDEN;
	}

	if (numberOfReplies <= DEFAULT_NUM_VISIBLE_REPLIES) {
		return ViewReplyOptions.ALL;
	}

	return ViewReplyOptions.LIMITED;
};

type GetNumOfHiddenRepliesProps = {
	replies: ReplyData[];
	latestReplyIds: Set<string>;
	currentReplyView: ViewReplyOptions;
};

export const getNumOfHiddenReplies = ({
	replies,
	latestReplyIds,
	currentReplyView,
}: GetNumOfHiddenRepliesProps) => {
	switch (currentReplyView) {
		case ViewReplyOptions.HIDDEN:
			return replies.length;
		case ViewReplyOptions.LIMITED:
			return replies.length - latestReplyIds.size;
		default:
			return 0;
	}
};
