import { useUnreadComments } from '@confluence/unread-comments';

import { scrollCommentsPanel } from './commentsPanelHelper';
import { ViewValues, useCommentsPanel } from './useCommentsPanel';

const commentsPanelListContainerId = 'comments-panel-list-container';
const getCommentThreadContainerId = (annotationId: string) =>
	`comment-thread-${annotationId}-container`;

export const useCommentsPanelScroll = () => {
	const [{ currentView }, { setCurrentView }] = useCommentsPanel();
	const [{ unreadCommentsListState }] = useUnreadComments();

	const scrollCommentsPanelToThread = (annotationId: string, isCommentThreadResolved?: boolean) => {
		const unreadCommentAnnotationIds =
			unreadCommentsListState.map((unreadComment) => unreadComment.threadKey) || [];
		const isUnreadComment = unreadCommentAnnotationIds.includes(annotationId);

		if (!isCommentThreadResolved) {
			// Update view based on comment status and current view
			if (
				(currentView === ViewValues.UNREAD || currentView === ViewValues.RESOLVED) &&
				!isUnreadComment
			) {
				setCurrentView(ViewValues.OPEN);
			} else if (currentView === ViewValues.RESOLVED && isUnreadComment) {
				setCurrentView(ViewValues.UNREAD);
			}
		}

		const commentThreadContainerId = getCommentThreadContainerId(annotationId);

		const scrollToThread = () => {
			scrollCommentsPanel({
				containerId: commentsPanelListContainerId,
				commentMarkerRef: commentThreadContainerId,
			});
		};

		// If comment thread container exists and view is not being updated, scroll immediately
		const container = document.getElementById(commentThreadContainerId);
		if (container && currentView !== undefined) {
			scrollToThread();
			return;
		}

		// Set up observer to watch for comment thread container creation
		const observer = new MutationObserver((_mutations, obs) => {
			// observe the comment thread container
			const containerUpdated = document.getElementById(commentThreadContainerId);
			if (containerUpdated) {
				obs.disconnect();
				scrollToThread();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
			attributes: true,
			attributeFilter: ['id'],
		});

		// Return cleanup function to ensure observer is disconnected
		return () => {
			observer.disconnect();
		};
	};

	return {
		scrollCommentsPanelToThread,
	};
};
