import { COMMENT_BATCH_SIZE, CommentActionType } from '@confluence/comments-data';
import { getValidDate } from '@confluence/comments-util/entry-points/dateUtils';
import type {
	AnnotationStatus,
	CommentData,
	CommentsDataMap,
	RemovedThreadsCommentTypeMap,
} from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';

import { SortValues } from './useCommentsPanel';

const SCROLLED_COMMENTS_HEADER_GAP_SIZE_IN_PIXELS = 8;

const getTotalOffsetAboveCommentsPanelThreads = (): number => {
	let objectSidebarPanelHeader = document.querySelector(
		"[data-testid='object-sidebar-panel-header']",
	);
	let additionalOffset = 0;
	if (!objectSidebarPanelHeader) {
		objectSidebarPanelHeader = document.querySelector(
			"[data-testid='scrolled-object-sidebar-panel-header']",
		);
		additionalOffset = SCROLLED_COMMENTS_HEADER_GAP_SIZE_IN_PIXELS;
	}

	const objectSidebarPanelHeaderHeight =
		objectSidebarPanelHeader?.getBoundingClientRect().height ?? 0;
	const objectHeaderHeight =
		document.querySelector("[data-testid='object-header-container']")?.getBoundingClientRect()
			.height ?? 0;
	const topNavContainerHeight =
		document.querySelector("[data-testid='grid-topNav']")?.getBoundingClientRect().height ?? 0;
	const bannerContainerHeight =
		document.querySelector("[data-testid='banner-container']")?.getBoundingClientRect().height ?? 0;

	return (
		objectSidebarPanelHeaderHeight +
		objectHeaderHeight +
		topNavContainerHeight +
		bannerContainerHeight +
		additionalOffset
	);
};

const isCommentThreadOutOfViewVertically = (commentThreadElement: Element): boolean => {
	const commentThreadRect = commentThreadElement.getBoundingClientRect();

	const totalPaddingAboveCommentThreadView = getTotalOffsetAboveCommentsPanelThreads();

	const objectSidebarPanelFooter = document.querySelector(
		"[data-testid='object-sidebar-panel-footer']",
	);
	const objectSidebarPanelFooterHeight =
		objectSidebarPanelFooter?.getBoundingClientRect().height ?? 0;

	return (
		commentThreadRect.bottom < totalPaddingAboveCommentThreadView || // Element is above the comments panel viewport
		commentThreadRect.top > window.innerHeight - objectSidebarPanelFooterHeight // Element is below the comments panel viewport
	);
};

export enum LocationValue {
	PANEL = 'panel',
	FOOTER = 'footer',
}

export const scrollCommentsPanel = ({
	containerId,
	commentMarkerRef,
}: {
	containerId: string;
	commentMarkerRef: string;
}) => {
	const container = document.querySelector(`[data-testid='${containerId}']`);
	if (container) {
		const element = document.querySelector(`[data-testid='${commentMarkerRef}']`);
		if (fg('comments_panel_updated_scrolling_behavior')) {
			if (element && isCommentThreadOutOfViewVertically(element)) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		} else {
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}
};

export const getAnnotationsToLoad = (orderedActiveAnnotationIdList: AnnotationStatus[]) => {
	return orderedActiveAnnotationIdList
		.slice(0, Math.min(orderedActiveAnnotationIdList.length, COMMENT_BATCH_SIZE))
		.filter((item) => !item.isLoaded) // only get the ones that are not loaded yet
		.map((item) => item.threadKey);
};

// organize a list of open comment threads that we have data for
export const getOpenCommentThreads = ({
	commentsDataMap,
	orderedActiveAnnotationIdList,
	removedThreadsMap,
	showInlineComments,
	showGeneralComments,
	location = LocationValue.PANEL,
}: {
	commentsDataMap: CommentsDataMap;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedThreadsMap: RemovedThreadsCommentTypeMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
	location?: LocationValue;
}) => {
	const openCommentThreads: CommentData[] = [];
	// inline comments
	if (showInlineComments && location === LocationValue.PANEL) {
		const removedAnnotationIds = new Set(Object.values(removedThreadsMap.inline));
		const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
			(item) => !removedAnnotationIds.has(item.threadKey),
		);
		const totalAnnotationsCount =
			filteredActiveAnnotationIds.length + Object.keys(removedThreadsMap).length;

		let activeIndex = 0; // Track position in filteredActiveAnnotationIds

		for (let i = 0; i < totalAnnotationsCount; i++) {
			let threadKey = '';

			if (removedThreadsMap.inline.hasOwnProperty(i)) {
				threadKey = removedThreadsMap.inline[i];
				const commentThread = commentsDataMap.inline[threadKey];
				if (commentThread) {
					openCommentThreads.push(commentThread);
				}
			} else if (activeIndex < filteredActiveAnnotationIds.length) {
				threadKey = filteredActiveAnnotationIds[activeIndex].threadKey;
				const commentThread = commentsDataMap.inline[threadKey];
				if (commentThread?.isOpen) {
					openCommentThreads.push(commentThread);
				}
				activeIndex++;
			}
		}
	}

	// general comments
	if (showGeneralComments || location === LocationValue.FOOTER) {
		// We can just push these in the order we have them because we have no concept of "active"
		const generalComments = Object.values(commentsDataMap.general).filter((generalComment) => {
			return (
				generalComment.isOpen ||
				generalComment.wasRemovedByAnotherUser === CommentActionType.DELETE_COMMENT
			);
		});
		openCommentThreads.push(...generalComments);
	}

	return openCommentThreads;
};

// organize a list of unread comment threads that we have data for
export const getUnreadCommentThreads = ({
	numUnreadComments,
	commentsDataMap,
	orderedActiveAnnotationIdList,
	removedThreadsMap,
	showInlineComments,
	showGeneralComments,
	location = LocationValue.PANEL,
}: {
	numUnreadComments: number;
	commentsDataMap: CommentsDataMap;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedThreadsMap: RemovedThreadsCommentTypeMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
	location?: LocationValue;
}) => {
	const unreadCommentThreads: CommentData[] = [];

	if (
		(Object.keys(commentsDataMap.inline).length > 0 ||
			Object.keys(commentsDataMap.general).length > 0) &&
		(numUnreadComments > 0 || Object.keys(removedThreadsMap.inline).length > 0)
	) {
		// inline comments
		if (showInlineComments && location === LocationValue.PANEL) {
			const removedAnnotationIds = new Set(Object.values(removedThreadsMap.inline));
			const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
				(item) => !removedAnnotationIds.has(item.threadKey),
			);
			const totalAnnotationsCount =
				filteredActiveAnnotationIds.length + Object.keys(removedThreadsMap.inline).length;

			let activeIndex = 0; // Track position in filteredActiveAnnotationIds

			for (let i = 0; i < totalAnnotationsCount; i++) {
				let threadKey = '';

				if (removedThreadsMap.inline.hasOwnProperty(i)) {
					threadKey = removedThreadsMap.inline[i];
					const commentThread = commentsDataMap.inline[threadKey];
					if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(commentThread);
					}
				} else if (activeIndex < filteredActiveAnnotationIds.length) {
					threadKey = filteredActiveAnnotationIds[activeIndex].threadKey;
					const commentThread = commentsDataMap.inline[threadKey];
					if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(commentThread);
					}
					activeIndex++;
				}
			}
		}

		// general comments
		if (showGeneralComments) {
			// We can just push these in the order we have them because we have no concept of "active"
			const generalComments = Object.values(commentsDataMap.general).filter((generalComment) => {
				return (
					(generalComment.isOpen ||
						generalComment.wasRemovedByAnotherUser === CommentActionType.DELETE_COMMENT) &&
					(generalComment.isUnread || generalComment.replies.some((reply) => reply.isUnread))
				);
			});
			unreadCommentThreads.push(...generalComments);
		}
	}

	return unreadCommentThreads;
};

export const getResolvedCommentThreads = ({
	commentsDataMap,
	showInlineComments,
	showGeneralComments,
	location = LocationValue.PANEL,
}: {
	commentsDataMap: CommentsDataMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
	location?: LocationValue;
}) => {
	const resolvedCommentThreads: CommentData[] = [];
	// inline comments
	if (showInlineComments && location === LocationValue.PANEL) {
		for (const key in commentsDataMap.inline) {
			const comment = commentsDataMap.inline[key];
			if (comment.isOpen === false) {
				resolvedCommentThreads.push(comment);
			}
		}
	}

	// general comments
	if (showGeneralComments) {
		for (const key in commentsDataMap.general) {
			const comment = commentsDataMap.general[key];
			if (comment.isOpen === false) {
				resolvedCommentThreads.push(comment);
			}
		}
	}

	return resolvedCommentThreads;
};

export const getSortedCommentThreads = (
	currentSort: SortValues,
	commentThreads: CommentData[],
): CommentData[] => {
	if (currentSort === SortValues.MOST_RECENT) {
		return [...commentThreads].sort((a, b) => {
			const dateA = getValidDate(a.createdAtNonLocalized);
			const dateB = getValidDate(b.createdAtNonLocalized);
			return dateB.getTime() - dateA.getTime();
		});
	}

	return commentThreads;
};
