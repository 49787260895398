import type { ApolloError } from 'apollo-client';
import type { MessageDescriptor } from 'react-intl-next';

import { getLogSafeErrorAttributes } from '@confluence/monitoring';

import { InlineCommentError } from './enum/InlineCommentError';
import { i18n } from './inlineCommentsi18n';

type ParsedError = {
	message: string;
	errorId?: string | null;
	statusCode?: number;
	isAuthorized: boolean;
};

export const parseError: (error: Error | ApolloError) => ParsedError = (error) => {
	const { message } = error;
	let errorMessage = '';

	const parsedError: ParsedError = {
		message: errorMessage,
		isAuthorized: false,
	};

	/* If this is a GraphQL error we need to extract the data out of it
	 * GraphQL errors that are returned from mutations always have the same format:
	 * GraphQL error: <GUID>: <EXCEPTION>: <REASON>
	 * The GUID correlates to the microsId so it unfortunately doesn't do much for us
	 * With this in mind, we want to extract the REASON as the message for translation
	 */
	if (message.indexOf('GraphQL error:') !== -1) {
		const reasonStartIdx = message.lastIndexOf(':') + 1;

		errorMessage = message.substr(reasonStartIdx, message.length - reasonStartIdx).trim();

		// Extract the traceId for highlight errors
		parsedError.errorId = getLogSafeErrorAttributes(error).traceId;
	} else {
		errorMessage = message;
	}

	parsedError.message = errorMessage;

	// If we have an ApolloError, extract the status code and the authorization status
	const { graphQLErrors } = error as ApolloError;
	if (graphQLErrors && graphQLErrors.length) {
		parsedError.statusCode = graphQLErrors?.[0]?.extensions?.statusCode;
		parsedError.isAuthorized = graphQLErrors?.[0]?.extensions?.data?.authorized || false;
	}

	return parsedError;
};

export const getTranslatedError: (message: string, id?: string) => MessageDescriptor = (
	message,
	id = '',
) => {
	let i18nError;

	// COMMENTS-4199 - This is our own handling of the error thrown by the editor/renderer that we wrap
	//				   in our own message, so to preserve the original error we just check for our message
	if (message.includes('Unable to apply annotation to document')) {
		i18nError = i18n.unableToApplyAnnotation;
	} else {
		switch (message) {
			case InlineCommentError.INVALID_SELECTION:
			case InlineCommentError.FAILED_TO_INSERT_MARK:
				i18nError = i18n.invalidHighlight;
				break;
			case InlineCommentError.STALE_SELECTION:
				i18nError = i18n.staleContent;
				break;
			case InlineCommentError.NO_ANONYMOUS_ACCESS:
				i18nError = i18n.actionNotPermitted;
				break;
			case InlineCommentError.NO_UPDATE_PERMS.replace('%d', id ? id! : ''):
				i18nError = i18n.editNotPermitted;
				break;
			case InlineCommentError.NO_DELETE_PERMS:
				i18nError = i18n.deleteNotPermitted;
				break;
			case InlineCommentError.NO_DELETE_WITH_CHILDREN:
				i18nError = i18n.deleteWithRepliesNotAllowed;
				break;
			case InlineCommentError.NO_CREATE_PERMS:
				i18nError = i18n.createNotPermitted;
				break;
			case InlineCommentError.UPDATE_COMMENT_NOT_FOUND.replace('%d', id ? id! : ''):
			case InlineCommentError.COMMENT_ALREADY_DELETED.replace('%d', id ? id! : ''):
			case InlineCommentError.CANNOT_RESOLVE_DELETED_COMMENT.replace('%d', id ? id! : ''):
				i18nError = i18n.commentAlreadyDeleted;
				break;
			case InlineCommentError.CANNOT_REPLY_TO_DELETED_PARENT:
				i18nError = i18n.replyNotAllowedParentDeleted;
				break;
			case InlineCommentError.INVALID_ARGUMENT_MATCHES:
				i18nError = i18n.contentHasBeenRemoved;
				break;
			case InlineCommentError.CANNOT_REPLY_TO_RESOLVED_COMMENT:
				i18nError = i18n.cannotReplyToResolvedComment;
				break;
			case InlineCommentError.CANNOT_UPDATE_RESOLVED_COMMENT:
				i18nError = i18n.cannotUpdateResolvedComment;
				break;
			case InlineCommentError.CONTENT_OUT_OF_DATE:
				i18nError = i18n.contentOutOfDate;
				break;
			case InlineCommentError.INVALID_ARGUMENT_CREATED_FROM:
			case InlineCommentError.CREATE_COMMENT_NOT_FOUND.replace('%d', id ? id! : ''):
			case InlineCommentError.PARENT_NOT_FOUND.replace('%d', id ? id! : ''):
			case InlineCommentError.INCOMPATIBLE_PARENT_TYPE:
			case InlineCommentError.DELETE_ERROR:
			default:
				i18nError = i18n.unexpectedError;
				break;
		}
	}

	return i18nError;
};

export const isHighlightError = (translatedMessage: MessageDescriptor) =>
	translatedMessage.id === i18n.invalidHighlight.id;

export const isUnexpectedError = (translatedMessage: MessageDescriptor) =>
	translatedMessage.id === i18n.unexpectedError.id;

export const isAnnotationError = (translatedMessage: MessageDescriptor) =>
	translatedMessage.id === i18n.unableToApplyAnnotation.id;

export const isOutOfDateError = (translatedMessage: MessageDescriptor) =>
	translatedMessage.id === i18n.contentOutOfDate.id ||
	translatedMessage.id === i18n.staleContent.id;

export const isAlreadyDeletedError = (translatedMessage: MessageDescriptor) =>
	translatedMessage.id === i18n.commentAlreadyDeleted.id;
